<template>
    <h1>
        Registrieren
    </h1>
    <br>
    <br>
    <h3>
        Du wurdest zu Unseld-Cloud eingeladen
    </h3>    
    <br>
    <div>
        <div>
            <input
                type="email"
                placeholder="Email"
                autocomplete="username"
                v-model="email">
        </div>
        <div>
            <input
                type="password"
                placeholder="Passwort"
                autocomplete="current-password"
                v-model="paswd">
        </div>
        <div>
            <p class="error">
                {{error}}
            </p>
        </div>
        <div>
            <button v-on:click="login()">
                <span class="material-icons-round">
                login
                </span>
                <span>
                    Registrieren & Anmelden
                </span>
            </button>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    methods: {
        login(){
            console.log(this.$route)
            let formData = new FormData;
            formData.append('email', this.email);
            formData.append('password', this.paswd);
            formData.append('token', this.$route.params.token);

            axios.post('/api/v1/user/register/submit/', formData)
            .then(response => {
                if(response.data.success === true){
                    this.$router.push('/');
                    this.$emit('changeLoginStatus', true);
                }else{
                    if(response.data.error){
                        this.error = response.data.error;
                    }
                }
            })
            .catch(error => {
                console.log(error);
            });
        }
    },
    data(){
        return {
            error: '',
            email: '',
            paswd: ''
        }
    },
    created(){
        
    }
}
</script>

<style scoped>
.error{
    color: #f00;
}
</style>